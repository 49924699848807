import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Sections from "@/components/sections"
import "../styles/app.scss"

const DynamicPage = ({ data, pageContext }) => {
  return (
    <>
      <SEO page={data.strapiPage.meta} site={data.strapi.meta.data} fallback={data.site.siteMetadata} />
      <Layout>
        <Sections sections={data.strapiPage.sections}/>
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      slug
      title
      sections
      meta {
        description
        title
        image {
          formats {
            medium {
              height
              width
              url
            }
            small {
              height
              width
              url
            }
            thumbnail {
              height
              width
              url
            }
          }
          url
          width
          height
        }
      }
    }
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
    strapi {
      meta {
        data {
          description
          title
          image {
            formats
            url
            width
            height
          }
        }
      }
    }
  }
`
